export enum PromotionType {
  sport = 0,
  casino = 1,
  live_casino = 2,
  p2p = 3,
  general = 4,
}

export interface IPromotionFilters {
  langId: number;
  type?: PromotionType;
}

export interface IPromotion {
  end_date: string;
  type: PromotionType;
  bonus_id: string;
  show_downtime: boolean;
  title: string;
  description: string;
  img: string;
  thumbnail_img: string;
  img_dark: string;
  thumbnail_img_dark: string;
}

export interface IPopupsFilters {
  page: string;
  langId: number;
}

enum ChannelType {
  WEB = 1,
  MOBILE = 2,
  TABLET = 3,
  BACKEND = 5,
}

export interface ISettingsFilters {
  langId: number;
  channelId: ChannelType;
}

export interface IMenusFilters {
  langId: number;
}

export interface IMeta {
  page: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
}

export interface IBannerGroup {
  id: number;
  page: string;
  displayName: string;
  isMobile: boolean;
  banners: IBanner[];
}

export interface IBanner {
  id: number;
  name: string;
  order: number;
  width: number;
  bannerItems: IBannerItem[];
}

export interface IBannerItem {
  id: number;
  name: string;
  startDate: number;
  endDate: number;
  order: number;
  status: number;
  translations: IBannerItemTranslation[];
}

export interface IBannerItemTranslation {
  id: number;
  langId: number;
  title: string;
  description: string;
  buttonLabel: string;
  img: string;
  imgDark: string;
  destinationUrl: string;
}
