import { IPlayer } from 'types/auth';

import { DEFAULT_LANGUAGE_ID, DirectionEnum } from 'config';
import { dispatchLogoutEvent } from 'utils/logoutListener';
import { DOCUMENT_DIR_KEY, getDocumentDirection } from 'utils/getDocumentDirection';

const USER_KEY = 'user';
const THEME_KEY = 'theme';
const USER_CURRENT_KEY = 'currency';
const TRANSLATES_KEY = 'translates';
const LANGUAGE_KEY = 'websiteLanguage';
const ACCESS_TOKEN_KEY = 'accessToken';
const WEBSITE_SETTINGS_KEY = 'websiteSettings';

const parse = (data?: string) => {
  try {
    if (data) {
      return JSON.parse(data);
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const themeStorage = {
  get: () => localStorage.getItem(THEME_KEY),
  remove: () => localStorage.removeItem(THEME_KEY),
  set: (type: string) => localStorage.setItem(THEME_KEY, type),
};

export const languageStorage = {
  get: (): number => Number(localStorage.getItem(LANGUAGE_KEY)) || DEFAULT_LANGUAGE_ID,
  remove: () => localStorage.removeItem(LANGUAGE_KEY),
  set: (land_id: number) => localStorage.setItem(LANGUAGE_KEY, String(land_id || DEFAULT_LANGUAGE_ID)),
};

export const accessStorage = {
  get: () => localStorage.getItem(ACCESS_TOKEN_KEY),
  remove: () => localStorage.removeItem(ACCESS_TOKEN_KEY),
  set: (token: string) => localStorage.setItem(ACCESS_TOKEN_KEY, token),
};

export const currencyStorage = {
  get: () => localStorage.getItem(USER_CURRENT_KEY),
  remove: () => localStorage.removeItem(USER_CURRENT_KEY),
  set: (currencyCode: string) => {
    localStorage.setItem(USER_CURRENT_KEY, currencyCode);
  },
};

export const userStorage = {
  get: (): IPlayer | null => parse(localStorage.getItem(USER_KEY)),
  remove: () => localStorage.removeItem(USER_KEY),
  set: (user: Partial<IPlayer>) => {
    currencyStorage.set(user.currency?.code);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  },
};

export const settingsStorage = {
  get: () => parse(localStorage.getItem(WEBSITE_SETTINGS_KEY)),
  remove: () => localStorage.removeItem(WEBSITE_SETTINGS_KEY),
  set: (settings) => {
    localStorage.setItem(WEBSITE_SETTINGS_KEY, JSON.stringify(settings));
  },
};

export const translatesStorage = {
  get: () => parse(localStorage.getItem(TRANSLATES_KEY)),
  remove: () => localStorage.removeItem(TRANSLATES_KEY),
  set: (translates) => {
    localStorage.setItem(TRANSLATES_KEY, JSON.stringify(translates));
  },
};

export const documentDirStorage = {
  get: () => getDocumentDirection(),
  remove: () => localStorage.removeItem(DOCUMENT_DIR_KEY),
  set: (dir: DirectionEnum) => {
    localStorage.setItem(DOCUMENT_DIR_KEY, dir);
  },
};

export const clearStorage = () => {
  localStorage.clear();
  document.cookie.split(';').forEach((cookie) => {
    document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });
  dispatchLogoutEvent();
};
