export interface IUserUpdateProps {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  country: string;
  city: string;
  addressOne: string;
  addressTwo: string;
  phoneNumber: string;
  gender: number;
  language: number;
}

export enum CasinoBetStatus {
  ACTIVE = 1,
  LOST,
  WON,
  BET_REVERSE,
}

export interface ICasinoHistoryFilters {
  place_date_from: Date;
  place_date_to: Date;
  user_id: number;
  page: number;
  size: number;
  langId: number;
  categoryId: number;
  status_id: CasinoBetStatus[];
}

export enum TransactionStatus {
  SUCCESS = 1,
  PENDING = 2,
  FAILED = 3,
  IN_REVIEW = 4,
  CANCELLED = 5,
  CANCELLED_BY_ADMIN = 6,
  REFUND = 7,
  REFUNDING = 8,
  EXPIRED = 9,
  PROCCESSING = 10,
  PARTIAL_REFUND = 11,
}

export enum TransactionType {
  BET = 1,
  WITHDRAW,
  MANUAL,
  DEPOSIT,
  USER_TO_USER,
  BONUS,
  BET_REVERSE,
  WON_REVERSE,
  WON,
  BALANCE_ADJUSTMENT,
  CASHBACK,
}

export interface ICasinoHistory {
  count: number;
  data: {
    id: number;
    created: Date;
    updated: Date;
    roundId: string;
    amount: number;
    wonAmount: number;
    statusId: CasinoBetStatus;
    gameId: number;
    gameName: string;
    providerName: string;
    categoryName: string;
    balanceAfter: number;
    balanceBefore: number;
  }[];
}

export interface IUserDocumentData {
  type_id: number;
  url: string;
  document_number: string;
}
