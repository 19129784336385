export interface IGame {
  category: number;
  categoryId: number;
  id: number;
  name: string;
  playCount: number;
  weight: number;
  hasDemo?: boolean;
  thumbnailImgUrl?: string;
  isNew?: boolean;
  isPopular?: boolean;
}

export interface TVBetGame {
  clientId: number;
  url: string;
  gameId: string;
  tagId: string;
  token: string;
  isFrameIntegration: boolean;
  aggregator: {
    name: string;
  };
}

export interface BetGamesTVGame {
  url: string;
  token: string;
  wsUrl: string;
  apiUrl: string;
  gameId: string;
  clientId: string;
  isFrameIntegration: boolean;
  aggregator: {
    name: string;
  };
}

export interface ICasinoCategory {
  id: number;
  name?: string;
  websiteId?: number;
}

export interface ICasinoGameFilter {
  tags?: number[];
  category?: number;
  name?: string;
  provider?: number;
  isNew?: boolean;
  isPopular?: boolean;
  favorites?: number;
  size?: number;
  page?: number;
  orderKey?: string;
  orderDirection?: 'asc' | 'desc';
}

export interface ICasinoProvider {
  name: string;
  logo?: string;
  gameCount?: number;
  id?: number;
  order: number;
}

export interface IStartGameProps {
  casinoGameId: number;
  demo: boolean;
  exitUrl?: string;
  https?: boolean;
}

export interface ICasinoTag {
  name: string;
  id: number;
  logo: string;
}

export enum ICasinoBetStatus {
  ACTIVE = 1,
  WON = 2,
  LOST = 3,
  BET_REVERSE = 4,
}

export interface IGameDetails {
  id: number;
  hasLobby: number;
  categoryId: number;
  name: string;
  thumbnailImgUrl: string;
  backgroundImg?: string;
  ratio: number;
  hasDemo: boolean;
  channelId: number;
  isNew: boolean;
  isPopular: boolean;
}

export interface IGamesTag {
  icon: string;
  iconDark: string;
  id: number;
  tag: string;
  order: number;
}
